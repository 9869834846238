//== XF Footer style, used on page template

#main-footer {
  margin-top: 5em;
  padding: 1.625em 0 1.5625em 0;
  border-top: 0.125em solid $color-shade-1;

  .h6_style .cmp-title__text {
    margin-left: 5rem;
  }

  .separator {
    display: none;
  }

  & .cmp-container {
    display: flex;
  }

  .image,
  .text {
    margin: 0 1em;
  }

  & .cmp-image {
    margin: 0;
  }

  .cmp-image__image {
    display: block;
    width: 9.2em;
    margin: 0 auto;
  }

  .cmp-title {
    margin: 0.5em 1em;
    text-align: center;
    @media only screen and (max-width: $breakpoint-mobile) {
      margin: 1em 1em;
    }
  }

  .cmp-text {
    margin: 0;
    text-align: center;
    & p {
      margin-block: 0;
      & a {
        color: $color-accent;
        text-decoration: none;
        white-space: nowrap;
        &:focus {
          color: $color-accent-lighter;
          border: 0.125em solid $color-accent-lighter;
          border-radius: 0.25em;
        }
        &:hover {
          color: $color-accent-darker;
        }
      }
    }
  }

  //=================== Media queries ===================

  @media (max-width: $breakpoint-mobile) {
    margin-top: 0;
  }
}

@media (max-width: $breakpoint-mobile) {
  #main-footer .h6_style .cmp-title__text {
    margin-left: 0;
  }
}
