.text-center{
    text-align: center !important;
}
.text-left{
    text-align: left !important;
}
.text-right{
    text-align: right !important;
}
.text-justify{
  text-align: justify !important;
}
//agregar padding t margenes landking

//FONTS

.font-grey{
    color: $color-gray !important;
}
.font-light-grey{
    color: $color-ligth-gray !important;
}
.font-dark-grey{
    color: $color-dark-gray !important;
}
.font-yellow{
    color: $color-yellow !important;
}
.font-black{
    color: $color-black !important;
}
.font-white{
    color: $color-white !important;
}

//BG

.bg-gray{
    background-color: $color-gray !important;
}
.bg-light-gray{
    background-color: $color-ligth-gray !important;
}
.bg-dark-gray{
    background-color: $color-dark-gray !important;
}
.bg-yellow{
    background-color: $color-yellow !important;
}
.bg-black{
    background-color: $color-black !important;
}
.bg-white{
    background-color: $color-white !important;
}

.padding-50  {
  padding: 50px
}
.padding-30 {
  padding: 30px
}
.padding-20  {
  padding: 20px
}

.margin-50 {
  margin: 50px !important;
}
.margin-30 {
  margin: 30px !important;
}
.margin-20 {
  margin: 20px !important;
}

.margin-left-20{
  margin-left: 20px !important;
}
.margin-left-40{
  margin-left: 40px !important;
}

.margin-right-20{
  margin-right: 20px !important;
}
.margin-left-40{
  margin-right: 40px !important;
}


@media (min-width: 801px) {
    .hide-desktop {
      display: none !important;
    }
}
    
@media (max-width: 800px) {
    .hide-mobile {
        display: none !important;
    }
}

.padding-home-gradient {
  &>.cmp-container {
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 50%);
  }
}

.centrar-elementos-flex {
  .cmp-container {
    .aem-Grid {
      display: flex;
      justify-content: center;
    }
  }
}

.bloque-home-pequeño {
    .cmp-container {
      height: 360px;
  
      .aem-Grid {
        position: relative;
        top: 40%;
      }
    }
  }
  
  .bloque-home-mediano {
    .cmp-container {
      height: 360px;
    }
  }
  
  .bloque-home-grande {
    .cmp-container {
      height: 720px;
    }
  }

  .container-center-vertical {
    .cmp-container {
      margin: 32% 0;
    }
  }
  
  @media (max-width: 767px) {
    .bloque-home-pequeño {
      .cmp-container {
        height: 188px;
  
        .aem-Grid {
          position: relative;
          top: 30%;
        }
      }
    }
  }