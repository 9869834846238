.cmp-carousel {
  margin: 0;
}
.cmp-carousel__content {
  position: relative;
}
.cmp-carousel__item {
}
.cmp-carousel__actions {
  position: absolute;
  right: 0.5em;
  bottom: 1.75em;
  display: flex;
  flex-direction: row;
  float: right;
}
.cmp-carousel__action {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0.125em;
  background-color: $color-shade-1;
  border-width: 0;
  border-radius: 20px;
  box-shadow: none;
  transform: scale(1);  
  transition: transform .5s ease-in-out;
  & .cmp-carousel__action-icon {
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      display: inline-block;
      width: 24px;
      height: 24px;
      transform: translate(-50%, -50%);
      content: '';
      mask-size: cover;
    }
  }
  &:focus {
    outline: none;
  }
}

.cmp-carousel__action-animation{
  transform: scale(1.5);
}

.cmp-carousel__action-icon img{
  width: 20px;
  margin-top: 4px;;
}

.cmp-carousel__actions-left{
  float: right;
  flex-direction: row;
  display: flex;
  position: absolute;
  top: 20.75em;
  left: .5em;
}
.cmp-carousel__actions-right{
  float: right;
flex-direction: row;
display: flex;
position: absolute;
top: 20.75em;
right: .5em;
}

.cmp-carousel__action--disabled {
}
/*.cmp-carousel__action--previous {
  & .cmp-carousel__action-icon {
    &:before {
     // mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00ODUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDg1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNLjI5MywxMy4wMjFhMSwxLDAsMCwxLDAtMS40MTVsNC45NS00Ljk1TC4yOTMsMS43MDdBMSwxLDAsMCwxLDEuNzA3LjI5M0w3LjM2NCw1Ljk1YTEsMSwwLDAsMSwwLDEuNDE0TDEuNzA3LDEzLjAyMWExLDEsMCwwLDEtMS40MTUsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUwMSA5OC42NTcpIHJvdGF0ZSgxODApIi8+PC9nPjwvc3ZnPg==');
      mask: url('/content/dam/nammi/general/iconos/carousel-left.png')
    }
  }
}
.cmp-carousel__action--next {
  & .cmp-carousel__action-icon {
    &:before {
      //mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00NDUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDQ1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNMzAuNzA3LTMuNTY1YTEsMSwwLDAsMSwwLTEuNDE0bDQuOTQ5LTQuOTUtNC45NDktNC45NWExLDEsMCwwLDEsMC0xLjQxNCwxLDEsMCwwLDEsMS40MTQsMGw1LjY1Nyw1LjY1NmExLDEsMCwwLDEsLjI5My43MDcsMSwxLDAsMCwxLS4yOTMuNzA3TDMyLjEyMS0zLjU2NWExLDEsMCwwLDEtLjcwNy4yOTNBMSwxLDAsMCwxLDMwLjcwNy0zLjU2NVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQyMi45MjkgMTAxLjkyOSkiLz48L2c+PC9zdmc+');
      mask: url('/content/dam/nammi/general/iconos/carousel-right.png')
    }
  }
}*/
.cmp-carousel__action--pause {
  display: none;
}
.cmp-carousel__action--play {
  display: none;
}
.cmp-carousel__action-icon {
}
.cmp-carousel__action-text {
  display: none;
}
.cmp-carousel__indicators {
  height: 1.5rem;
  margin: 5px 0px;
}
.cmp-carousel__indicator {
  width: 20px;
  height: 0.125rem;
  margin: auto 0.125rem 0 0.125rem;
  background-color: $color-ligth-gray;
  border-radius: 24px;
  height: 20px;
}

.cmp-carousel__indicator--active {
  background-color: $color-dark-gray;
}


.arrow-gray .cmp-carousel__indicator{
  background-color: $color-gray;
}
.arrow-dark-gray .cmp-carousel__indicator{
  background-color: $color-gray;
}
.arrow-light-gray .cmp-carousel__indicator{
  background-color: $color-dark-gray;
}
.arrow-yellow .cmp-carousel__indicator{
  background-color: $color-gray;
}
.arrow-hide .cmp-carousel__indicator{
  background-color: $color-gray;
}

.arrow-gray .cmp-carousel__indicator--active{
  background-color: $color-dark-gray;
}
.arrow-dark-gray .cmp-carousel__indicator--active{
  background-color: $color-gray;
}
.arrow-light-gray .cmp-carousel__indicator--active{
  background-color: $color-ligth-gray;
}
.arrow-yellow .cmp-carousel__indicator--active{
  background-color: $color-yellow;
}
.arrow-hide .cmp-carousel__indicator--active{
  background-color: $color-dark-gray;
}




.arrow-yellow .cmp-carousel__action{
  background-color: $color-yellow !important;
}
.arrow-gray .cmp-carousel__action{
  background-color: $color-gray !important;
}
.arrow-light-gray .cmp-carousel__action{
  background-color: $color-ligth-gray !important;
}
.arrow-dark-gray .cmp-carousel__action{
  background-color: $color-dark-gray !important;
}

.arrow-hide .cmp-carousel__action{
  display: none;
}


@media (max-width: 1100px) {
  .cmp-carousel__actions-left, .cmp-carousel__actions-right{
    top: 6.75em;
  }
}