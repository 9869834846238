.cmp-image {
  margin-top: 2.25em;
}
.cmp-image__link {
}
.cmp-image__image {
  display: block;
}
.cmp-image__title {
}

.padding-20-img .cmp-image{
  padding: 20px;
}

.padding-30-img .cmp-image{
  padding: 30px;
}

.padding-40-img .cmp-image{
  padding: 40px;
}


.margin-l-10-img .cmp-image{
  margin-left: 10px;
}
.margin-l-20-img .cmp-image{
  margin-left: 20px;
}
.margin-l-30-img .cmp-image{
  margin-left: 30px;
}

.margin-r-10-img .cmp-image{
  margin-right: 10px;
}
.margin-r-20-img .cmp-image{
  margin-right: 20px;
}
.margin-r-30-img .cmp-image{
  margin-right: 30px;
}
