.cmp-tabs {
  margin: 0.5em;
}

.cmp-tabs__tablist {
  justify-content: space-around;
  border-bottom: 3px solid #ccc !important;
}
.cmp-tabs__tab {
  height: 2.625em;
  margin: 0;
  padding: 0.5625em 1em 0.6875em 1em;
  color: $color-gray;
  font-size: 1em;
  outline: none;
  border-bottom:  0px solid transparent !important;
  width: 100%;
  text-align: center;
  &:hover {
    color: $color-dark-gray;
  }
  //&:focus {
  //  border-radius: 0.25em;
  //  border: 0.125em solid $color-accent-lighter;
  //}
}

.cmp-tabs__tab:nth-of-type(1):nth-last-of-type(2), 
.cmp-tabs__tab:nth-of-type(2):nth-last-of-type(1) {
  width: 50%;
}
.cmp-tabs__tab:nth-of-type(1):nth-last-of-type(3), 
.cmp-tabs__tab:nth-of-type(2):nth-last-of-type(2), 
.cmp-tabs__tab:nth-of-type(3):nth-last-of-type(1) {
  width: 33.33%;
}
.cmp-tabs__tab:nth-of-type(1):nth-last-of-type(4), 
.cmp-tabs__tab:nth-of-type(2):nth-last-of-type(3), 
.cmp-tabs__tab:nth-of-type(3):nth-last-of-type(2), 
.cmp-tabs__tab:nth-of-type(4):nth-last-of-type(1) {
  width: 24%;
}
.cmp-tabs__tab:nth-of-type(1):nth-last-of-type(5), 
.cmp-tabs__tab:nth-of-type(2):nth-last-of-type(4), 
.cmp-tabs__tab:nth-of-type(3):nth-last-of-type(3), 
.cmp-tabs__tab:nth-of-type(4):nth-last-of-type(2), 
.cmp-tabs__tab:nth-of-type(5):nth-last-of-type(1) {
  width: 20%;
}


.cmp-tabs__tab::after{
  display:block;
  content: '';
  padding-bottom: 13px;
  border-bottom: solid 3px #feb912;  
  transform: scaleX(0);  
  transition: transform .5s ease-in-out;
}

.cmp-tabs__tab--active {
  padding-bottom: 13px;
  transform: scaleX(1);
}

.cmp-tabs__tab--active:after { padding-bottom: 13px; transform: scaleX(1); }

.cmp-tabs__tabpanel {
}
.cmp-tabs__tabpanel--active {
  padding-right: 0.5em;
  padding-left: 0.5em;
}


@media (max-width: 900px){
  .cmp-tabs__tab {
    height: 40px;
    font-size: 0.8em;
    text-align: left;
    border-bottom: 3px solid #ccc !important;
  }

  .cmp-tabs__tablist{
    justify-content: space-around !important;
    padding-bottom: 0px;
  }

  .cmp-tabs__tab:nth-of-type(1):nth-last-of-type(2), 
.cmp-tabs__tab:nth-of-type(2):nth-last-of-type(1) {
  width: 100%;
}
.cmp-tabs__tab:nth-of-type(1):nth-last-of-type(3), 
.cmp-tabs__tab:nth-of-type(2):nth-last-of-type(2), 
.cmp-tabs__tab:nth-of-type(3):nth-last-of-type(1) {
  width: 100%;
}
.cmp-tabs__tab:nth-of-type(1):nth-last-of-type(4), 
.cmp-tabs__tab:nth-of-type(2):nth-last-of-type(3), 
.cmp-tabs__tab:nth-of-type(3):nth-last-of-type(2), 
.cmp-tabs__tab:nth-of-type(4):nth-last-of-type(1) {
  width: 100%;
}
.cmp-tabs__tab:nth-of-type(1):nth-last-of-type(5), 
.cmp-tabs__tab:nth-of-type(2):nth-last-of-type(4), 
.cmp-tabs__tab:nth-of-type(3):nth-last-of-type(3), 
.cmp-tabs__tab:nth-of-type(4):nth-last-of-type(2), 
.cmp-tabs__tab:nth-of-type(5):nth-last-of-type(1) {
  width: 100%;
}
  
  .cmp-tabs__tab--active {
    padding-bottom: 10px;
    transform: scaleX(1);
  }
  
  .cmp-tabs__tab--active:after { padding-bottom: 10px; transform: scaleX(1); }
  .cmp-tabs__tablist{
    border-bottom: 0px solid transparent !important;
  }
}