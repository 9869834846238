.cmp-text {
  margin: 0.75rem 0 0 0;
  color: $color-text;
  font-weight: normal;
  font-size: 1em;
  & b {
    font-weight: bold;
  }
  & p a {
    color: $color-accent;
    text-decoration: none;
    border: 0.125em solid $color-transparent;
    border-radius: 0.25em;
    &:hover {
      color: $color-accent-darker;
      text-decoration: underline;
      text-decoration-thickness: 0.125em;
      text-decoration-color: $color-accent-darker;
      text-decoration-style: solid;
    }
    &:focus {
      text-decoration: underline;
      border-color: $color-accent;
      outline: none;
      text-decoration-thickness: 0.125em;
      text-decoration-color: $color-accent;
      text-decoration-style: solid;
    }
  }
  & blockquote {
    margin: 0.75rem 0;
    font-size: 1.75em;
    font-family: 'titleFont', 'titleFont Fallback';
    font-style: italic;
    line-height: 2.375rem;
    margin-inline: 0;
  }
  & ul li::marker {
    content: '-  ';
  }
}
.cmp-text__paragraph {
}


.text-center .cmp-text{
  text-align: center !important;
}
.text-left .cmp-text{
  text-align: left !important;
}
.text-right .cmp-text{
  text-align: right !important;
}
.text-justify .cmp-text{
text-align: justify !important;
}

.font-gray .cmp-text{
  color: $color-gray !important;
  & a{
    color: $color-gray !important;
    &:hover {
      color: $color-gray !important;
      text-decoration-color: $color-gray !important;
    }
  }
}
.font-light-gray .cmp-text{
  color: $color-ligth-gray !important;
  & a{
    color: $color-ligth-gray !important;
    &:hover {
      color: $color-ligth-gray !important;
      text-decoration-color: $color-ligth-gray !important;
    }
  }
}
.font-dark-gray .cmp-text{
  color: $color-dark-gray !important;
  & a{
    color: $color-dark-gray !important;
    &:hover {
      color: $color-dark-gray !important;
      text-decoration-color: $color-dark-gray !important;
    }
  }
}
.font-yellow .cmp-text {
  color: $color-yellow !important;
  color: $color-yellow !important;
  & a{
    color: $color-yellow !important;
    &:hover {
      color: $color-yellow !important;
      text-decoration-color: $color-yellow !important;
    }
  }
}
.font-black .cmp-text{
  color: $color-black !important;
  & a{
    color: $color-black !important;
    &:hover {
      color: $color-black !important;
      text-decoration-color: $color-black !important;
    }
  }
}
.font-white .cmp-text{
  color: $color-white !important;
  & a{
    color: $color-white !important;
    &:hover {
      color: $color-white !important;
      text-decoration-color: $color-white !important;
    }
  }
}