.homepage-content-container {
  margin-top: 2.5em;
}



@media (max-width: $breakpoint-mobile) {
  .homepage-content-container {
    margin-top: 0;
  }
}
