.cmp-container {
    font-family: defaultFont, serif;
}

.padding-50 .cmp-container {
    padding: 50px
  }
  .padding-30 .cmp-container {
    padding: 30px
  }
  .padding-20 .cmp-container {
    padding: 20px
  }
  
  .margin-50 .cmp-container {
    margin: 50px
  }
  .margin-30 .cmp-container {
    margin: 30px
  }
  .margin-20 .cmp-container {
    margin: 20px
  }
  

.container-ancho-33 {
    width: 33% !important;
    .cmp-container {
      width: 100% !important;
    }
}
  
.container-ancho-25 {
    width: 25% !important;
    .cmp-container {
        width: 100% !important;
    }
}
.container-ancho-50 {
    width: 50% !important;
    .cmp-container {
        width: 100% !important;
    }
}
.container-center-vertical {
    .cmp-container {
        margin: 32% 0;
    }
}
.centrar-elementos-flex {
    .cmp-container {
        .aem-Grid {
            display: flex;
            justify-content: center;
        }
    }
}
.padding-30-text {
    .cmp-container {
        &>div {
            padding: 0 30px;
        }
    }
}
  
.padding-40-text {
    .cmp-container {
        &>div {
        padding: 0 40px;
        }
    }
}
  
.padding-50-text {
    .cmp-container {
        &>div {
        padding: 0 50px;
        }
    }
}
  
.padding-80-text {
    .cmp-container {
        &>div {
        padding: 0 80px;
        }
    }
}

@media (max-width: 767px) {

    .padding-25-percent,
    .padding-50-text,
    .padding-40-text,
    .padding-30-text,
    .padding-80-text {
        .cmp-container {

        .text,
        .title {
            padding: 0 30px;
        }

        .aem-Grid {
            &>div {
            padding: 0 30px;
            }
        }
        }
    }
}