.cmp-button {
  width: fit-content;
  height: fit-content;
  margin: 0.5em 0.5em;
  padding: 0.5625em 1em 0.6875em 1em;
  border-style: solid;
  border-width: 0;
  border-radius: 1.56em;
  display: block;
}

.button a.cmp-button {
  display: inline-block;
}

.cmp-button {
  background: $color-dark-gray;
  color: #fff;
  text-decoration: none;
  border-radius: 3px;
  padding: .5625em 1.3em .6875em;
}
.cmp-button__text {
  font-weight: 300;
  text-decoration: none;
}
.cmp-button__icon {
}

.btn-yellow .cmp-button{
  background-color: $color-yellow !important;
}
.btn-yellow .cmp-button:hover{
  background-color: #e9a708 !important;
}
.btn-gray .cmp-button{
  background-color: $color-gray !important;
}
.btn-gray .cmp-button:hover{
  background-color: $color-gray !important;
}
.btn-light-gray .cmp-button{
  background-color: $color-ligth-gray !important;
}
.btn-light-gray .cmp-button:hover{
  background-color: $color-ligth-gray !important;
}
.btn-dark-gray .cmp-button{
  background-color: $color-dark-gray !important;
}
.btn-dark-gray .cmp-button:hover{
  background-color: $color-dark-gray !important;
}

.text-gray .cmp-button__text{
  color: $color-gray !important;
}
.text-ligth-gray .cmp-button__text{
  color: $color-ligth-gray !important;
}
.text-dark-gray .cmp-button__text{
  color: $color-dark-gray !important;
}
.text-yellow .cmp-button__text{
  color: $color-yellow !important;
}
.text-black .cmp-button__text{
  color: $color-black !important;
}
.text-white .cmp-button__text{
  color: $color-white !important;
}

.nam-btn-small .cmp-button{
  padding: .4em 1.5em !important;
}

.nam-btn-large .cmp-button{
  padding: 1.5em 5.7em !important;
}

.nam-btn-full .cmp-button{
  width: 100% !important;
  padding-top: 2em;
  padding-bottom: 2em;
}

.nam-btn-50 .cmp-button{
  width: 50% !important;
}

.nam-btn-33 .cmp-button{
  width: 33% !important;
}

.width-30 .cmp-button {
  width: 30%;
  min-width: 310px;
}

.width-50 .cmp-button {
  width: 50%;
  min-width: 310px;
}

.full-width .cmp-button {
  width: 100%;
  min-width: 310px;
}

.content-buttons-center .cmp-container{
  display: flex;
  justify-content: center;
}
.content-buttons-center-espaced .cmp-container{
  display: flex;
  justify-content: space-around;
}
.content-buttons-end .cmp-container{
  display: flex;
  justify-content: flex-end;
}
.content-buttons-start .cmp-container{
  display: flex;
  justify-content: flex-start;
}

.nam-margin-left-0 .cmp-button{
  margin-left: 0 !important;
}

.nam-margin-right-0 .cmp-button{
  margin-right: 0 !important;
}

@media (max-width: 1000px) {
  .content-buttons-center .cmp-container, .content-buttons-start .cmp-container, .content-buttons-end .cmp-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .nam-btn-full .cmp-button{
    width: 96% !important;
    margin: 0px !important;
  }
  
}

.animated-button .cmp-button {
   display: inline-block;
 padding: .75rem 1.25rem;
 border-radius: 2px;
 color: #fff;
 font-size: 1rem;
 transition: all .3s;
 position: relative;
 overflow: hidden;
 z-index: 1;
 text-decoration: none;
 }

 .animated-button .cmp-button::after {
   content: '';
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: $color-gray;
   border-radius: 2px;
   z-index: -2;
 }
 .animated-button .cmp-button::before {
   content: '';
   position: absolute;
   bottom: 0;
   left: 0;
   width: 0%;
   height: 100%;
   background-color: $color-yellow;
   transition: all .3s;
   border-radius: 2px;
   z-index: -1;
   text-decoration: none;
 }
 .animated-button .cmp-button:hover {
   color: $color-white;
 }
 .animated-button .cmp-button:hover::before {
     width: 100%;
 }
 .animated-button .cmp-button:hover {
   color: $color-black;
   text-decoration: none;
 }



 .animated-button .cmp-button {
   display: inline-block;
 padding: .75rem 1.25rem;
 border-radius: 2px;
 color: #fff;
 font-size: 1rem;
 transition: all .3s;
 position: relative;
 overflow: hidden;
 z-index: 1;
 text-decoration: none;
 }

 .animated-button .cmp-button::after {
   content: '';
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: $color-gray;
   border-radius: 2px;
   z-index: -2;
 }
 .animated-button .cmp-button::before {
   content: '';
   position: absolute;
   bottom: 0;
   left: 0;
   width: 0%;
   height: 100%;
   background-color: $color-yellow;
   transition: all .3s;
   border-radius: 2px;
   z-index: -1;
   text-decoration: none;
 }
 .animated-button .cmp-button:hover {
   color: $color-white;
 }
 .animated-button .cmp-button:hover::before {
     width: 100%;
 }
 .animated-button .cmp-button:hover {
   color: $color-black;
   text-decoration: none;
 }

 //BOTON GRIS
 .btn-dark-gray.animated-button .cmp-button {
  display: inline-block;
padding: .75rem 1.25rem;
border-radius: 2px;
color: #fff;
font-size: 1rem;
transition: all .3s;
position: relative;
overflow: hidden;
z-index: 1;
text-decoration: none;
}

.btn-dark-gray.animated-button .cmp-button::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-gray !important;
  border-radius: 2px;
  z-index: -2;
}
.btn-dark-gray.animated-button .cmp-button::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: $color-dark-gray !important;
  transition: all .3s;
  border-radius: 2px;
  z-index: -1;
  text-decoration: none;
}
.btn-dark-gray.animated-button .cmp-button:hover {
  color: $color-white;
}
.btn-dark-gray.animated-button .cmp-button:hover::before {
    width: 100%;
}
.btn-dark-gray.animated-button .cmp-button:hover {
  color: $color-white !important;
  text-decoration: none;
}
