.cmp-title {
  margin-top: 2.5rem;
  margin-bottom: 0.75rem;
}
.cmp-title__text {
  margin: 0;
  line-height: 1.1em;
}
.cmp-title__link {
  color: $color-accent;
  text-decoration: none;
  border: 0.125rem solid $color-transparent;
  border-radius: 0.25rem;
  &:hover {
    color: $color-accent-darker;
    text-decoration: underline;
    text-decoration-color: $color-accent-darker;
    text-decoration-thickness: 0.125rem;
  }
  &:focus {
    color: $color-accent;
    text-decoration: underline;
    border-color: $color-accent-lighter;
    outline: none;
    text-decoration-color: $color-accent-lighter;
    text-decoration-thickness: 0.125rem;
  }
}

//colors 

.font-gray .cmp-title{
  color: $color-gray !important;
}
.font-light-gray .cmp-title{
  color: $color-ligth-gray !important;
}
.font-dark-gray .cmp-title{
  color: $color-dark-gray !important;
}
.font-yellow .cmp-title{
  color: $color-yellow !important;
}
.font-black .cmp-title{
  color: $color-black !important;
}
.font-white .cmp-title{
  color: $color-white !important;
}

//colors url
.font-gray .cmp-title__link{
  color: $color-gray !important;
  &:hover {
    color: $color-gray;
    text-decoration-color: $color-gray !important;
  }
}
.font-light-gray .cmp-title__link{
  color: $color-ligth-gray !important;
  &:hover {
    color: $color-ligth-gray !important;
    text-decoration-color: $color-ligth-gray !important;
  }
}
.font-dark-gray .cmp-title__link{
  color: $color-dark-gray !important;
  &:hover {
    color: $color-dark-gray !important;
    text-decoration-color: $color-dark-gray !important;
  }
}
.font-yellow .cmp-title__link{
  color: $color-yellow !important;
  &:hover {
    color: $color-yellow !important;
    text-decoration-color: $color-yellow !important;
  }
}
.font-black .cmp-title__link{
  color: $color-black !important;
  &:hover {
    color: $color-black !important;
    text-decoration-color: $color-black !important;
  }
}
.font-white .cmp-title__link{
  color: $color-white !important;
  &:hover {
    color: $color-white !important;
    text-decoration-color: $color-white !important;
  }
}